import {Disclaimer} from '@Env/interfaces/disclaimer';
import {DisclaimerType} from '@Shared/enums/disclaimer-type';
import {AustralianStates} from '@Shared/enums/locations';

export const auDisclaimers: Disclaimer[] = [
  {
    type: DisclaimerType.dataCopyRight,
    value:
      'The State of Victoria owns the copyright in the property sales data and reproduction of that data in any way without the consent of the State of Victoria will constitute a breach of the Copyright Act 1968 (Cth). The State of Victoria does not warrant the accuracy or completeness of the licensed material and any person using or relying upon such information does so on the basis that the State of Victoria accepts no responsibility or liability whatsoever for any errors, faults, defects or omissions in the information supplied.',
    states: [AustralianStates.VIC],
  },
  {
    type: DisclaimerType.dataCopyRight,
    value:
      'DISCLAIMER Change to Derivative Product - Approval OFFICIAL The State of South Australia (“the State”), including without limitation the Offices of the Valuer–General and Registrar General, DOES NOT ENDORSE any of the goods or services provided by Valocity Pty Ltd and its related entities. The State notes that this good or service is NOT PRODUCED by the State (including without limitation the Offices of the Valuer-General and Registrar-General) and in particular (but without limitation) if the good or service is a valuation, it is NOT A VALUATION produced or endorsed by the State or the Valuer-General.',
    states: [AustralianStates.SA],
  },
  {
    type: DisclaimerType.owners,
    value: 'Ownership information derived from Non-Government sources. Please use as a guide only.',
    states: [
      AustralianStates.ACT,
      AustralianStates.NT,
      AustralianStates.QLD,
      AustralianStates.SA,
      AustralianStates.TAS,
    ],
  },
];
